import $ from 'jquery';
import 'jquery.marquee';
import 'slick-carousel';
import Panzoom from '@panzoom/panzoom';
import './modals';
import {
  scrollToSection,
  fadeInElement,
  fadeOutElement,
  sendGAEvent,
  isTouchDevice,
  breakpoints,
} from './utilities';
import { handleInputs, handleContactFormSendingError } from './form-modify';

$(() => {
  function ifFormWasOpenFromSource(formContainerId) {
    const wpConfirmation = document.querySelector(`${formContainerId}`);

    const params = new URLSearchParams(window.location.search);

    if (wpConfirmation && params.has('source')) {
      window.addEventListener('load', () => {
        $('body').addClass('hide-overflow');

        if (!isTouchDevice()) {
          $('body').css('padding-right', '17px');
        }
      });
      fadeInElement($(formContainerId)[0], 'grid');
    }
  }

  ifFormWasOpenFromSource('#job_offer_form_modal');

  function ifContactFormHasBeenSent(formContainerId, customDisplaySetting) {
    const wpConfirmation = document.querySelector(
      `${formContainerId} .wpforms-confirmation-container-full`
    );

    if ((wpConfirmation, customDisplaySetting)) {
      $('body').addClass('hide-overflow');
      $(formContainerId).css('display', 'grid');
      $(formContainerId).addClass('fade-in');
      $(formContainerId).addClass('get_a_quote--form-sent');

      if (customDisplaySetting) {
        $(formContainerId).css('display', customDisplaySetting);
      }
    }
  }

  ifContactFormHasBeenSent('#get_a_quote_modal');
  ifContactFormHasBeenSent('#job_offer_form_modal');

  $('.wpforms-field-file-upload').each(function () {
    const buttonContent = $(this).find('.wpforms-field-label');
    const fieldDescription = $(this).find('.wpforms-field-description');
    const dzMessage = $(this).find('.dz-message');

    dzMessage.find('.modern-title').html(buttonContent.html());

    if (!dzMessage.find('.modern-hint')[0]) {
      dzMessage.append("<span class='modern-hint'></span>");
    }

    dzMessage.find('.modern-hint').html(fieldDescription.html());
  });

  if (currentLanguage === 'pl_PL') {
    document.body.style.setProperty('--uploader-remove-text-content', "'Usuń'");
    document.body.style.setProperty('--uploader-remove-right', '4ch');
  }

  const goBackButtons = document.querySelectorAll('.go_back_button');

  goBackButtons.forEach((goBackButton) => {
    goBackButton.addEventListener('click', () => {
      history.back();
    });
  });

  // Hamburger button
  const body = document.querySelector('body');
  const mainHeader = document.querySelector('header');
  const menuHamburgerButton = document.querySelector('.menu__hamburger');
  menuHamburgerButton.addEventListener('click', () => {
    mainHeader.classList.toggle('active');
    menuHamburgerButton.classList.toggle('active');
    // To avoid double scrollbars
    body.classList.toggle('hide-overflow');
  });

  // Marquee effect slider
  const initToggleAnimationState = (elem) => {
    $(elem).on({
      mouseenter: () => {
        marqueeEffElement
          .find('.js-marquee-wrapper')
          .css('animation-play-state', 'paused');
      },
      mouseleave: () => {
        marqueeEffElement
          .find('.js-marquee-wrapper')
          .css('animation-play-state', 'running');
      },
    });
  };

  const marqueeEffElement = $('.quote-marquee');
  const marqueeConfig = {
    gap: 0,
    delayBeforeStart: 1,
    direction: 'left',
    speed: 50,
    pauseOnHover: true,
    duplicated: true,
    startVisible: true,
  };
  let windowWidth = $(window).width();

  $(window).on('load', function () {
    $.each(marqueeEffElement, (index, el) => {
      $(el).marquee({
        ...marqueeConfig,
        pauseOnHover: !$(el).hasClass('noHoverStop'),
      });
      if (!$(el).hasClass('noHoverStop')) {
        initToggleAnimationState(el);
      }
    });

    $(window).on('resize', function (e) {
      // Determine if window was actually resized
      // caused problems on ios -> scroll fired resize
      if ($(window).width() !== windowWidth) {
        $.each(marqueeEffElement, (index, el) => {
          $(el).marquee('destroy');
          $(el).marquee({
            ...marqueeConfig,
            pauseOnHover: !$(el).hasClass('noHoverStop'),
          });
          if (!$(el).hasClass('noHoverStop')) {
            initToggleAnimationState(el);
          }
        });

        windowWidth = $(window).width();
      }
    });
  });

  // Slick carousel
  $('#slick_carousel').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: true,
  });

  // Dropdown list in Header on Mobile devices >1100px
  const dropdownArrows = document.querySelectorAll(
    '.dropdown_arrow__container'
  );
  dropdownArrows.forEach((dropdownArrow) => {
    dropdownArrow.addEventListener('click', function () {
      event.preventDefault();
      this.parentElement.parentElement.classList.toggle('active');
    });
  });

  // Menu item with children fade-in and show on >=1200px
  $('.menu_item-has-children').on('mouseover', function () {
    if (window.innerWidth >= 1200) {
      fadeInElement($(this).find('.sub_menu'), 'grid');
      fadeInElement($('.over-page_shadow'), 'block');
    }
  });
  // Menu item with children fade-out and hide on >=1200px
  $('.menu_item-has-children').on('mouseleave', function () {
    if (window.innerWidth >= 1200) {
      fadeOutElement($(this).find('.sub_menu'));
      fadeOutElement($('.over-page_shadow'));
    }
  });

  $('#learnMore').on('click', () => scrollToSection('.our_services'));

  // Our-services
  const services = document.querySelectorAll(
    '.our_services__list .our_services__list__item'
  );
  services.forEach((service, index) => {
    if (index === 0) {
      if (window.innerWidth > breakpoints.tabletUp) {
        $(service).addClass('active');
      }

      const clonedService = $(service).clone();

      $('#main_expand').empty();
      $('#main_expand').append(
        clonedService.find('.our_services__expand')[0].childNodes
      );
    }

    service.querySelector('button').addEventListener('click', () => {
      const currentActiveElement = document.querySelector(
        '.our_services__list > .our_services__list__item.active'
      );

      if (
        currentActiveElement !== service ||
        window.innerWidth > breakpoints.tabletUp
      ) {
        $(currentActiveElement).removeClass('active');
      }

      const clonedService = $(service).clone();

      $('#main_expand').empty();
      $('#main_expand').append(
        clonedService.find('.our_services__expand')[0].childNodes
      );
      $(service).toggleClass('active');
    });
  });

  // Recruitment Plan
  const recruitmentParts = document.querySelectorAll(
    '.recruitment_parts__list .recruitment_parts__list__item'
  );
  recruitmentParts.forEach((recruitmentPart, index) => {
    if (index === 0) {
      if (window.innerWidth > breakpoints.tabletUp) {
        $(recruitmentPart).addClass('active');
      }

      const clonedPart = $(recruitmentPart).clone();

      $('#main_expand').empty();
      $('#main_expand').append(
        clonedPart.find('.recruitment_parts__expand')[0].childNodes
      );
    }

    recruitmentPart.querySelector('button').addEventListener('click', () => {
      const currentActiveElement = document.querySelector(
        '.recruitment_parts__list__item.active'
      );

      if (
        currentActiveElement !== recruitmentPart ||
        window.innerWidth > breakpoints.tabletUp
      ) {
        $(currentActiveElement).removeClass('active');
      }

      const clonedPart = $(recruitmentPart).clone();

      $('#main_expand').empty();
      $('#main_expand').append(
        clonedPart.find('.recruitment_parts__expand')[0].childNodes
      );
      $(recruitmentPart).toggleClass('active');
    });
  });

  // Recruitment plan navigation buttons

  document.querySelectorAll('a.navigation_button').forEach((link) => {
    link.addEventListener('click', function (event) {
      window.location.replace(link.href);
      event.preventDefault();
    });
  });

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const intersecting = entry.isIntersecting;
      const navigationButton = $(`#button_${entry.target.id}`);

      if (navigationButton) {
        if (intersecting) {
          navigationButton.toggleClass('active', true);
          navigationButton.toggleClass('inactive', false);
        } else {
          navigationButton.toggleClass('active', false);
          navigationButton.toggleClass('inactive', true);
        }
      }
    });
  });

  const stepsIds = document.querySelectorAll(
    'section.recruitment_steps .recruitment_step_box'
  );
  stepsIds.forEach((step) => {
    observer.observe(step);
  });

  // Recruitment Plan Diagram
  const diagram = document.getElementsByClassName(
    'recruitment-diagram-wrapper-inner'
  )[0];

  if (diagram) {
    diagram.setAttribute('preserveAspectRatio', 'xMinYMin meet');

    if (isTouchDevice()) {
      Panzoom(diagram);
      let timeout;
      let removeHintTimeout;
      const onScroll = () => {
        if (timeout) {
          clearTimeout(timeout);
          clearTimeout(removeHintTimeout);
        }
        const diagramHint = $('#diagram-hint');
        $(diagramHint).removeClass('hint-removed');

        const isHintVisible = $(diagramHint).hasClass('hint-visible');

        if (!isHintVisible) {
          $(diagramHint).addClass('hint-visible');
        }

        timeout = setTimeout(() => {
          $(diagramHint).removeClass('hint-visible');
          removeHintTimeout = setTimeout(() => {
            $(diagramHint).addClass('hint-removed');
          }, 200);
        }, 500);
      };

      document.addEventListener('touchstart', (e) => {
        const diagramWrapper = document.querySelector(
          '.recruitment-diagram-wrapper-inner'
        );
        const { x, y, width, height } = diagramWrapper.getBoundingClientRect();
        const touchX = e.touches[0].clientX;
        const touchY = e.touches[0].clientY;

        if (
          touchX > x &&
          touchX < x + width &&
          touchY > y &&
          touchY < y + height
        ) {
          document.removeEventListener('scroll', onScroll);

          // for ios
          document.removeEventListener('touchmove', onScroll);
          return;
        }
        document.addEventListener('scroll', onScroll);

        // for ios
        document.addEventListener('touchmove', onScroll);
      });
    }
  }

  const buttonExpand = $('#recruitment_diagram_button_expand');
  const buttonShrink = $('#recruitment_diagram_button_shrink');

  const toggleExpandClass = (buttonToShow) => (e) => {
    const diagramWrapper = document.querySelector(
      '.recruitment-diagram-wrapper-inner'
    );

    $(e.target).addClass('hidden');
    $(buttonToShow).removeClass('hidden');
    $(diagramWrapper).toggleClass('diagram_collapsed diagram_expanded');
  };

  buttonExpand.on('click', toggleExpandClass(buttonShrink));

  buttonShrink.on('click', toggleExpandClass(buttonExpand));

  // Recruitment plan FAQ collapsibles

  function expandContract() {
    const content = $(this).find('.content');
    content.slideToggle(300);
    $(this).find('.collapsible').toggleClass('active');
  }

  const collapsibles = document.getElementsByClassName('expand-container');

  if (collapsibles) {
    collapsibles.forEach((collapsible) => {
      collapsible.addEventListener('click', expandContract);
    });
  }

  // Map
  function initMap() {
    const mapWrapper = document.getElementById('map');

    if (mapWrapper) {
      const latitude = mapWrapper.getAttribute('data-latitude');
      const longitude = mapWrapper.getAttribute('data-longitude');

      const centerLatitude =
        Number(mapWrapper.getAttribute('data-center-latitude')) ||
        50.06421584072227;
      const centerLongitude =
        Number(mapWrapper.getAttribute('data-center-longitude')) ||
        19.945048388964103;

      const mapZoom = Number(mapWrapper.getAttribute('data-zoom')) || 11;

      const companyLocation = { lat: +latitude, lng: +longitude };
      const map = new google.maps.Map(mapWrapper, {
        center: { lat: centerLatitude, lng: centerLongitude },
        zoom: mapZoom,
      });

      const svgMarker = {
        path: 'M19,21a5,5,0,1,1,5-5,5,5,0,0,1-5,5M19,2A14,14,0,0,0,5,16C5,26.5,19,42,19,42S33,26.5,33,16A14,14,0,0,0,19,2Z',
        fillColor: '#15b7df',
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 1,
        anchor: new google.maps.Point(14, 40),
      };

      const marker = new google.maps.Marker({
        position: companyLocation,
        map,
        title: 'Fingoweb',
        icon: svgMarker,
      });

      map.setOptions({
        styles,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      });
    }
  }

  const styles = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'landscape.natural.terrain',
      elementType: 'geometry.fill',
      stylers: [
        {
          weight: 1.5,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#c7c7c7',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ff0000',
        },
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#bbbbbb',
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ];

  initMap();

  // ChatGPT example section buttons
  const exampleButtons = $('.gpt_example_content_button');
  exampleButtons.each((_, item) => {
    $(item).on('click', () => {
      exampleButtons.each((_, button) => {
        $(button).toggleClass('active', false);
      });
      $(item).toggleClass('active', true);
    });
  });

  // Handle Forms Modifies
  const contactErrorBox = document.getElementById('error-message-box');
  const contactSubmitButton = document.querySelector(
    '.contact button[name="wpforms[submit]"]'
  );

  if (contactErrorBox && contactSubmitButton) {
    handleContactFormSendingError(contactSubmitButton, contactErrorBox);
  }

  handleInputs();

  // button na stronie Careers
  $('#checkOffers').on('click', () => scrollToSection('.jobs'));

  $('.get_a_quote__form:not(.career__form) > form').on('submit', () => {
    sendGAEvent('Get a quote');
  });

  $('.get_free_consultation__form > form').on('submit', () => {
    sendGAEvent('Free consultation');
  });

  $('.contact__form > form').on('submit', () => {
    sendGAEvent('Contact');
  });

  $('.get_a_quote__form.career__form > form').on('submit', () => {
    sendGAEvent('Career');
  });

  $('.pricing_form > form').on('submit', () => {
    sendGAEvent('ChatGPT Landing Contact');
  });
});
