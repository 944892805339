import $ from 'jquery';
import { fadeInElement, fadeOutElement, isTouchDevice } from './utilities';

const addOpenModalListeners = function (openButton, modalSelector) {
  $(openButton).on('click', function () {
    $('body').addClass('hide-overflow');

    if (!isTouchDevice()) {
      $('body').css('padding-right', '17px');
    }

    fadeInElement(
      $(modalSelector)[0],
      modalSelector === '#get_free_consultation_modal' ? 'flex' : 'grid'
    );
  });
};

const addCloseModalListeners = function (closeButton, modalSelector) {
  $(closeButton).on('click', () => {
    fadeOutElement($(modalSelector)[0]);

    window.setTimeout(() => {
      $('body').removeClass('hide-overflow');

      if (!isTouchDevice()) {
        $('body').css('padding-right', '0');
      }
    }, 220);
  });
};

const handleModal = function (openButtons, closeButtons, modalSelector) {
  // Open modal buttons
  $(openButtons).each(function () {
    addOpenModalListeners(this, modalSelector);
  });

  // Close modal button
  $(closeButtons).each(function () {
    addCloseModalListeners(this, modalSelector);
  });
};

export default handleModal;
