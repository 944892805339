import $ from 'jquery';

export const scrollToSection = (section) => {
  $(section)[0].scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};

// Adds .fade-in class to the element, and sets its display
export const fadeInElement = (element, display) => {
  $(element).removeClass('fade-out');
  $(element).css('display', display);

  setTimeout(() => {
    $(element).addClass('fade-in');
  }, 1);
};

// Adds .fade-out class to the element, and sets its display to none
export const fadeOutElement = (element) => {
  $(element).removeClass('fade-in');
  $(element).addClass('fade-out');
  $(element).on(
    'transitionend webkitTransitionEnd oTransitionEnd',
    function () {
      if ($(this).hasClass('fade-out')) {
        $(this).css('display', 'none');
        $(element).removeClass('fade-out');
      }
    }
  );
};

// Detect if the device is touch device
export const isTouchDevice = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

export const sendGAEvent = (eventLabel) => {
  if ('ga' in window) {
    ga('send', {
      hitType: 'event',
      eventCategory: 'Form submit',
      eventAction: 'submit',
      eventLabel: eventLabel
    })
  }
}

export const breakpoints = {
  tabletUp: 768,
};
